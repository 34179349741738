import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/sidebar';

const Billings = () => {
  const [billings, setBillings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [memberSearch, setMemberSearch] = useState("");
  const [memberResults, setMemberResults] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [billingDate, setBillingDate] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("Not Paid");
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchBillings = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}billings/`, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setBillings(response.data);
      } catch (err) {
        console.error('Error fetching billings:', err);
      }
    };
    fetchBillings();
  }, [API_URL]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    });
  };

  // Toggle modal visibility
  const toggleModal = () => {
    setShowModal(!showModal);
    setSelectedMember(null);
    setSelectedPackage(null);
    setMemberSearch("");
    setBillingDate("");
    setErrorMessage(""); // Clear error message on modal close
  };

  // Fetch members based on search input
  const handleMemberSearch = async (e) => {
    setMemberSearch(e.target.value);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}users/search-members/?query=${e.target.value}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setMemberResults(response.data);
    } catch (err) {
      console.error('Error searching members:', err);
    }
  };

  // Select a member and use their package details
  const handleSelectMember = (member) => {
    setSelectedMember(member);
    setMemberSearch(`${member.first_name} ${member.last_name}`);
    setMemberResults([]);

    // Check if the selected member has a package assigned
    if (member.package) {
      setSelectedPackage(member.package);
      setErrorMessage(""); // Clear any previous error message
    } else {
      setSelectedPackage(null);
      setErrorMessage("Selected member does not have an assigned package."); // Display error message if no package
    }
  };

  // Handle form submission to create billing entry
  const handleCreateBilling = async () => {
    if (!selectedMember || !selectedPackage) {
      setErrorMessage("Please select a member with a valid package.");
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const gymId = selectedMember.gym;
      const data = {
        gym_member_id: selectedMember.id,
        gym_id: gymId,
        billing_date: billingDate,
        payment_status: paymentStatus,
        amount: selectedPackage.amount,
      };

      await axios.post(`${API_URL}billings/create/`, data, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // Update billings list and close modal if successful
      setBillings([...billings, { ...data, member_name: `${selectedMember.first_name} ${selectedMember.last_name}`, package_name: selectedPackage.package_name }]);
      toggleModal(); // Close the modal
    } catch (err) {
      // Check for specific error messages based on backend response
      if (err.response && err.response.status === 400) {
        const error = err.response.data.error;
        if (error.includes("Receipt for this member for")) {
          setErrorMessage("A receipt for this member for the selected month already exists.");
        } else {
          setErrorMessage("An error occurred. Please try again.");
        }
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Billings Overview</h1>
          <button onClick={toggleModal} className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800 transition-all duration-300">
            Create Receipt
          </button>
        </div>
        
        {/* Modal */}
        {showModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-xl font-bold mb-4">Create Billing Receipt</h2>
              
              {/* Member Search */}
              <div>
                <label className="block text-gray-700 mb-2">Member</label>
                <input
                  type="text"
                  value={memberSearch}
                  onChange={handleMemberSearch}
                  placeholder="Search for member..."
                  className="w-full border border-gray-300 p-2 rounded mb-2"
                />
                <div className="bg-white shadow-md max-h-32 overflow-y-auto">
                  {memberResults.map((member) => (
                    <div
                      key={member.id}
                      onClick={() => handleSelectMember(member)}
                      className="cursor-pointer p-2 hover:bg-gray-200"
                    >
                      {member.first_name} {member.last_name}
                    </div>
                  ))}
                </div>
              </div>
              
              {/* Display Package Amount */}
              <div className="mt-4">
                <label className="block text-gray-700 mb-2">Amount</label>
                <input
                  type="text"
                  value={selectedPackage ? `Ksh ${selectedPackage.amount}` : 'N/A'}
                  readOnly
                  className="w-full border border-gray-300 p-2 rounded mb-2"
                />
              </div>
              
              {/* Calendar Input for Billing Date */}
              <div className="mt-4">
                <label className="block text-gray-700 mb-2">Billing Date</label>
                <input
                  type="date"
                  value={billingDate}
                  onChange={(e) => setBillingDate(e.target.value)}
                  className="w-full border border-gray-300 p-2 rounded mb-2"
                />
              </div>
              
              {/* Payment Status */}
              <div className="mt-4">
                <label className="block text-gray-700 mb-2">Payment Status</label>
                <select
                  value={paymentStatus}
                  onChange={(e) => setPaymentStatus(e.target.value)}
                  className="w-full border border-gray-300 p-2 rounded"
                >
                  <option value="Not Paid">Not Paid</option>
                  <option value="Paid">Paid</option>
                </select>
              </div>
              
              {/* Error Message */}
              {errorMessage && (
                <div className="text-red-500 text-sm mt-2">
                  {errorMessage}
                </div>
              )}

              {/* Modal Actions */}
              <div className="flex justify-end mt-4">
                <button
                  onClick={toggleModal}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-400 mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleCreateBilling}
                  disabled={!selectedMember || !selectedPackage || !billingDate}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Billings Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-lg rounded-lg">
            <thead>
              <tr className="w-full bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Member Name</th>
                <th className="py-3 px-6 text-left">Package Name</th>
                <th className="py-3 px-6 text-left">Billing Date</th>
                <th className="py-3 px-6 text-left">Amount</th>
                <th className="py-3 px-6 text-left">Payment Status</th>
                <th className="py-3 px-6 text-left">Action</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {billings.map((billing, index) => (
                <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6">{billing.member_name}</td>
                  <td className="py-3 px-6">{billing.package_name}</td>
                  <td className="py-3 px-6">{formatDate(billing.billing_date)}</td>
                  <td className="py-3 px-6">Ksh {billing.amount}</td>
                  <td className="py-3 px-6">
                    <span className={`py-1 px-3 rounded-full text-xs font-semibold ${
                      billing.payment_status === 'Paid' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'
                    }`}>
                      {billing.payment_status}
                    </span>
                  </td>
                  <td className="py-3 px-6">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400 transition-all duration-300">Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
};

export default Billings;
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MapComponent from '../Map/map';

const CreateGym = () => {
  const [gymName, setGymName] = useState('');
  const [gymDescription, setGymDescription] = useState('');
  const [gymPhoneNumber, setGymPhoneNumber] = useState('');
  const [gymLandmark, setGymLandmark] = useState('');
  const [gymProfilePicture, setGymProfilePicture] = useState(null);
  const [gymLocation, setGymLocation] = useState({ latitude: '', longitude: '' });
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('gym_name', gymName);
    formData.append('gym_description', gymDescription);
    formData.append('gym_phone_number', gymPhoneNumber);
    formData.append('gym_landmark', gymLandmark);
    formData.append('profile_image', gymProfilePicture);
    formData.append('gym_latitude', gymLocation.latitude);
    formData.append('gym_longitude', gymLocation.longitude);
  
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}gyms/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${token}`,
        },
      });
      navigate('/home'); // Redirect on successful gym creation
    } catch (err) {
      console.error('Error creating gym:', err.response ? err.response.data : err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setGymName('');
    setGymDescription('');
    setGymPhoneNumber('');
    setGymLandmark('');
    setGymProfilePicture(null);
    setGymLocation({ latitude: '', longitude: '' });
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-medium text-gray-900 mb-4 text-center">Create Gym</h3>
        {loading && <div className="loader text-center">Creating Gym...</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="gym-name" className="block text-sm font-medium text-gray-700">Gym Name</label>
            <input
              type="text"
              id="gym-name"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Enter gym name"
              value={gymName}
              onChange={(e) => setGymName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="gym-description" className="block text-sm font-medium text-gray-700">Gym Description</label>
            <textarea
              id="gym-description"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Enter gym description"
              rows="3"
              value={gymDescription}
              onChange={(e) => setGymDescription(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="mb-4">
            <label htmlFor="gym-phone-number" className="block text-sm font-medium text-gray-700">Gym Phone Number</label>
            <input
              type="text"
              id="gym-phone-number"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Enter gym phone number"
              value={gymPhoneNumber}
              onChange={(e) => setGymPhoneNumber(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="gym-landmark" className="block text-sm font-medium text-gray-700">Gym Landmark</label>
            <input
              type="text"
              id="gym-landmark"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Enter gym landmark"
              value={gymLandmark}
              onChange={(e) => setGymLandmark(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="gym-profile-picture" className="block text-sm font-medium text-gray-700">Gym Logo</label>
            <input
              type="file"
              id="gym-profile-picture"
              className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              onChange={(e) => setGymProfilePicture(e.target.files[0])}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="gym-map" className="block text-sm font-medium text-gray-700">Gym Location</label>
            <MapComponent setGymLocation={setGymLocation} />
          </div>
          <div className="mt-6 flex items-center justify-end space-x-3">
            <button
              type="button"
              onClick={handleCancel}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loading}
            >
              {loading ? 'Creating...' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateGym;
import React, { useEffect, useState, useRef } from 'react';
import Sidebar from '../Sidebar/sidebar';
import axios from 'axios';
import { Chart, LineElement, CategoryScale, LinearScale, PointElement, LineController, Filler } from 'chart.js';

const GymDashboard = () => {
  const chartRef = useRef(null);

  // State variables to store API data
  const [totalMembers, setTotalMembers] = useState(0);
  const [femaleMembers, setFemaleMembers] = useState(0);
  const [maleMembers, setMaleMembers] = useState(0);
  const [totalProjectedRevenue, setTotalProjectedRevenue] = useState(0);
  const [totalGoals, setTotalGoals] = useState(0);
  const [completedGoals, setCompletedGoals] = useState(0);
  const [totalPackages, setTotalPackages] = useState(0); // New state for Total Packages
  const [newMembersData, setNewMembersData] = useState([]);  

  useEffect(() => {
    // Fetch data from the dashboard-overview API
    const fetchDashboardData = async () => {
      try {
        const token = localStorage.getItem('token');
        const API_URL = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${API_URL}dashboard/dashboard-overview/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        // Update state with the response data
        const data = response.data;
        setTotalMembers(data.members.total_members);
        setFemaleMembers(data.members.female_members);
        setMaleMembers(data.members.male_members);
        setTotalProjectedRevenue(data.total_projected_revenue);
        setTotalGoals(data.goals.total_goals);
        setCompletedGoals(data.goals.completed_goals);
        setTotalPackages(data.total_packages);  // Set Total Packages data
        setNewMembersData(data.new_members_data);  // Store new members data for the chart

      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, []);

  useEffect(() => {
    if (newMembersData.length > 0) {
      // Extract labels and data from newMembersData
      const labels = newMembersData.map(entry => entry.month);
      const dataPoints = newMembersData.map(entry => entry.new_members);

      // Register Chart.js components
      Chart.register(LineElement, CategoryScale, LinearScale, PointElement, LineController, Filler);

      // Destroy previous chart instance if it exists
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      const ctx = document.getElementById('newMembersChart').getContext('2d');
      chartRef.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            label: 'New Members Added',
            data: dataPoints,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderWidth: 2,
            fill: 'origin',
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          scales: {
            y: { beginAtZero: true }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function(context) {
                  return `New Members: ${context.raw}`;
                }
              }
            }
          }
        }
      });

      // Cleanup function to destroy the chart instance on component unmount
      return () => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
      };
    }
  }, [newMembersData]);  // Re-run chart setup whenever newMembersData changes

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow p-6">
        <div className="mb-6">
          <h1 className="text-2xl font-bold">Dashboard Overview</h1>
          <div className="bg-white p-4 rounded-lg shadow mt-4">
            <h2 className="font-bold text-xl">Statistics</h2>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-bold">Total Members</h3>
                <p className="text-2xl">{totalMembers}</p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-bold">Female Members</h3>
                <p className="text-2xl">{femaleMembers}</p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-bold">Male Members</h3>
                <p className="text-2xl">{maleMembers}</p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-bold">Total Monthly Expected Revenue</h3>
                <p className="text-2xl">Ksh {totalProjectedRevenue}</p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-bold">Total Goals</h3>
                <p className="text-2xl">{totalGoals}</p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-bold">Completed Goals</h3>
                <p className="text-2xl">{completedGoals}</p>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg"> {/* New block for Total Packages */}
                <h3 className="font-bold">Total Packages</h3>
                <p className="text-2xl">{totalPackages}</p>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow mt-6">
            <h2 className="font-bold text-xl">New Members Added</h2>
            <div style={{ height: '400px' }}>
              <canvas id="newMembersChart" className="mt-4"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GymDashboard;